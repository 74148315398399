import React from 'react';
import Helmet from 'react-helmet';
import { Parallax } from 'react-scroll-parallax';
import { StaticImage } from "gatsby-plugin-image";

import Header from '../components/Header';
import Layout from "../components/layout";

class SpringOnMain extends React.Component {
  render() {
    return(
      <Layout>
        <Helmet title={"Spring on Main Case Study | Rob Allgood Design"} />
        <svg className="top green" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1366 603">
           <path class="st0" d="M1366,183c-80,0-178-131-336-131S822,0,822,0h544V183z"/>
        </svg>
        <Header />
          <div className="container content">
            <div className="row">
              <h3>The Challenge</h3>
              <p>New restaurant Spring on Main was looking for a brand that evoked the travel destination of hey-day of Eureka Springs, Arkansas. Here is how I took their vision from concept to reality. After logo exploration, I made several concepts for the client. With their feedback, I found the right mix of vintage vacation and Ozark charm.</p>
            </div>
          </div>
          <div className="container content">
            <h3>The Brand</h3>
            <h5 className="margin-top-2">Logo</h5>
            <div className="row margin-bottom-2">
                <StaticImage
                  src="../images/Spring-on-Main-Logo-Color.png"
                  alt="Spring on Main logo"
                  placeholder="blurred"
                  className="center margin-bottom-2"
                  width={400}
                />
                <p>The logo makes use of stylized typography similar to that used in vintage travel advertising. The container is a nod to both the scenic Ozark Mountains and the natural springs for which Eureka Springs is named.</p>
            </div>
            <h5 className="margin-top-2">Color</h5>
            <div className="row margin-bottom-2">
              <div className="row">
                <div className="col colors" style={{marginLeft: -5}}>
                  <svg width="100%" height="100%" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="50" cy="50" r="50" fill="#6F937F"/>
                  </svg>
                  <p className="text-center">6F937F</p>
                </div>
                <div className="col colors">
                  <svg width="100%" height="100%" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="50" cy="50" r="50" fill="#DAECDD"/>
                  </svg>
                  <p className="text-center">DAECDD</p>
                </div>
                <div className="col colors">
                  <svg width="100%" height="100%" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="50" cy="50" r="50" fill="#E16D53"/>
                  </svg>
                  <p className="text-center">E16D53</p>
                </div>
                <div className="col colors">
                  <svg width="100%" height="100%" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="50" cy="50" r="50" fill="#F7CF74"/>
                  </svg>
                  <p className="text-center">F7CF74</p>
                </div>
                <div className="col colors">
                  <svg width="100%" height="100%" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="50" cy="50" r="50" fill="#141928"/>
                  </svg>
                  <p className="text-center">141928</p>
                </div>
              </div>
            </div>
            <h5 style={{marginTop: 80}}>Typography</h5>
            <div className="row" className="margin-bottom-2">
              <div className="row">
              <StaticImage
                src="../images/Spring-Type.png"
                alt="Detail of brand typography for Spring on Main"
                placeholder="blurred"
              />
              </div>
            </div>
          </div>
          <div className="container content">
            <h3>Applications</h3>
            <div className="row" style={{marginBottom: 20}}>
              <div className="col">
                <StaticImage
                  src="../images/Spring-Can.png"
                  alt="packaging for Spring on Main beer"
                  placeholder="blurred"
                  aspectRatio={1.3 / 1}
                />
              </div>
              <div className="col">
                <StaticImage
                  src="../images/spring-app-1.png"
                  alt="branded promotion of Spring on Main"
                  placeholder="blurred"
                  aspectRatio={1.3 / 1}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <StaticImage
                  src="../images/Spring-T-Shirt.png"
                  alt="tshirt with spring t-shirt logo"
                  placeholder="blurred"
                  aspectRatio={1.3 / 1}
                />
              </div>
              <div className="col">
                <StaticImage
                  src="../images/spring-app-2.png"
                  alt="demo image of application"
                  placeholder="blurred"
                  aspectRatio={1.3 / 1}
                />
              </div>
            </div>
          </div>
      </Layout>
    )
  }
}

export default SpringOnMain
